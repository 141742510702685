<template>
    <div class="page">
        <img class="box"
             src="./assets/bg.png">

        <div class="content">
            <!-- <div class="txt_remind mt-2"><a href="http://mallrobot-akc.oss-cn-hangzhou.aliyuncs.com/gnsy.mp4">视频教程</a></div> -->
            <transition name="van-fade" mode="out-in">
                <verify-code v-if="showVerifyCode" :soNumber="soNumber" @verifySuccess="timeControl"/>
                <div v-else class="mt-6 flex column">
                    <div class="text-center color-gray fs-12">ID：{{ userInfo.userSerialNo }}</div>
                    <div class="relative mt-2">
                        <img v-if="encode"
                             :src="encode"
                             class="encode">
                        <img v-if="!encode"
                             src="../../assets/template.png"
                             class="encode">
                        <div v-if="!encode && !second"
                             class="login_avatar_txt flex h-center">
                            正在加载...
                        </div>
                        <div v-if="!encode && second"
                             class="login_avatar_txt flex h-center">
                            正在登录...
                        </div>
                        <div v-if="!encode" class="loadEffect">
                            <span v-for="i in 8" :key="i"/>
                        </div>
                    </div>
                    <!-- <div class="fs-12 color-gray mt-1">
                      用需要登录的微信账号扫描上方二维码
                    </div> -->

                    <div class="color-primary mt-1 fs-12"
                         v-if="haveCode==2">微信授权二维码
                    </div>
                    <div class="color-gray mt-1 fs-12"
                         v-if="haveCode==3">微信登录二维码
                    </div>
                    <div class="mt-1 fs-12 color-warning"
                         v-if="haveCode==3">（使用手机微信扫码 即可登录 长按识别无效）
                    </div>
                    <button class="mt-4 reflesh"
                            @click="refreshs()">刷新二维码
                    </button>
                </div>
            </transition>


            <u class="color-warning mt-4 flex h-center" @click="show=true">如何让爱跟推稳定，不容易掉线？</u>

            <!--			<div v-if="robot.online === 2"
                       class="mt-30 flex column">
                    <img :src="robot.headImgUrl"
                         class="avatar">
                    <div class="color-danger fs-12 b mt-2">{{ robot.nickName }}</div>
                  </div>-->

            <div class="dirver mt-6"/>

            <div class="mt-4 color-danger ml-3 mr-3 fs-12">
                请注意：请将二维码截图保存至其他手机或电脑，然后手机扫码登录，长按识别无效。
            </div>

            <!-- <div class="flex h-center mt-2">
              <img src="../../assets/banner.jpg" class="banner" />
            </div> -->

            <div class="fs-12 color-gray mt-3 ml-3 mr-3 flex v-top">
                <div>1.</div>
                使用手机截屏功能将二维码保存发送给其他手机或者电脑；
            </div>

            <div class="flex h-center">
                <img src="../../assets/login3.gif"
                     class="login1 mt-1 ml-3 mr-3"/>
            </div>

            <div class="fs-12 color-gray mt-1 ml-3 mr-3">2. 打开手机，使用微信-扫描二维码功能扫码登陆；</div>

            <div class="flex h-center mt-2">
                <img src="../../assets/login4.gif"
                     class="login1 mt-1 ml-3 mr-3"/>
            </div>

            <div class="fs-12 color-gray mt-1 ml-3 mr-3">3.在微信登录确认页面点击确认即可登陆成功；</div>

            <div class="flex h-center mt-2">
                <img src="../../assets/login5.gif"
                     class="login1 mt-1 ml-3 mr-3"/>
            </div>

            <div class="fs-12 color-gray mt-1 ml-3 mr-3 flex v-top">
                <div>4.</div>
                返回页面时，等待几秒，系统获取账号信息后即可登陆爱跟推。
            </div>
        </div>

        <!-- <a href="http://mallrobot-akc.oss-cn-hangzhou.aliyuncs.com/akc_smdl.mp4"><img src="../../assets/fixed_btn.png" class="fixed_btn" /></a> -->

        <van-popup v-model:show="show"
                   style="width: 75%"
                   class="flex column pl-3 pr-3">
            <div class="fs-14 b mt-4">如何让爱跟推稳定，不易掉线？</div>
            <div class="fs-12 mt-3 color-gray text-left wd_100s ml-1">1.不主动关闭微信登陆；</div>
            <div class="fs-12 mt-1 color-gray text-left wd_100s ml-1">2.不随意更换登陆设备；</div>
            <div class="fs-12 mt-1 color-gray text-left wd_100s ml-1">3.避免在使用爱跟推过程中登录其他电脑、平板等设备；
            </div>
            <div class="fs-12 mt-1 color-gray text-left wd_100s ml-1">4.为了避免账号异常，请勿重复扫码。</div>
            <div class="fs-12 mt-1 color-gray text-left wd_100s ml-1">
                5.在首次登录爱跟推的24小时内，避免频繁操作，建议发送消息的间隔时间为5-8分钟
            </div>
            <div class="fs-12 mt-1 color-warning b mt-5 ml-1">注意：如出现上述情况，爱跟推将在一周内有一定概率被破掉线。
            </div>
            <div class="divider_dotted mt-4"></div>
            <button class="mt-4 mb-4 reflesh b"
                    @click="show=false">我知道了
            </button>
        </van-popup>
        <Protocol v-model:visible="protocolVisible" @agree="initData" :userCode="userInfo.userSerialNo"/>

    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {Dialog} from 'vant'
import {defineAsyncComponent} from 'vue'

export default {
    name: 'Index',

    components: {
        Protocol: defineAsyncComponent(() =>
            import('../../components/Protocol')
        ),
        VerifyCode: defineAsyncComponent(() =>
            import('../../components/VerifyCode.vue')
        ),
    }, data() {
        return {
            showVerifyCode: false,
            protocolVisible: true,
            loading: false,
            robot: {},
            robotValue: '',
            wxType: 1,
            soNumber: '',

            loginType: 0,
            inviteCode: '',
            encode: '',
            control: 0,
            timer: '',

            timeControls: '',
            haveCode: 1,
            show: false,
            second: false
            // toast1: ''
        }
    },
    computed: {
        ...mapGetters(['userInfo'])
    },
    created() {
        const _this = this
        // _this.initData()
    },
    unmounted() {
        let _this = this
        clearInterval(_this.timeControls)
    },
    methods: {
        async initData() {
            const _this = this
            _this.control = 2
            await _this.getData()
            await _this.loginWx()
        },
        getData() {
            const _this = this
            return new Promise((resolve, reject) => {
                _this
                    .$post('/WxRobot/GetPorts', {wxType: _this.wxType})
                    .then((res) => {
                        if (res) {
                            _this.robot = res[0]
                        }
                        resolve()
                    })
                    .catch((e) => {
                        if (e.message == '未包含TokenKey') {
                            // _this.$route.go(-1)
                            _this.$router.go(-1)
                        }
                        reject()
                    })
            })
        },
        async loginWx() {
            const _this = this
            // console.log(_this.robot.deviceId)
            await _this
                .$post('/WxRobot/WxRobotLogin', {
                    deviceId: _this.robot.deviceId,
                    robotWxId: _this.robot.robotWxId,
                    expireDate: _this.robot.expireDate,
                    clientIp: '',
                    robotWxType: _this.robot.robotWxType,
                    loginType: _this.loginType,
                    inviteCode: _this.inviteCode
                })
                .then((res) => {
                    _this.soNumber = res
                })
            // _this.getEncode()
            _this.timeControl()
        },
        getEncode() {
            const _this = this
            return new Promise((resolve, reject) => {
                _this.$get('/WxRobot/GetWxLoginResult', {
                    soNumber: _this.soNumber
                })
                    .then((res) => {
                        let content = {}
                        let msg = ''
                        if (res) {
                            if (res && res.state) {
                                content = res
                                // if (res.state != "5" && _this.toast1) {
                                //   _this.toast1.clear();
                                // }
                                switch (res.state) {
                                    case '-1':
                                        clearInterval(_this.timeControls)

                                        _this.$Dialog.confirm({
                                            title: content.logoutReason ? content.logoutReason : '二维码已过期',
                                            message: '是否刷新？'
                                        })
                                            .then(() => {
                                                _this.encode = ''
                                                _this.initData()
                                            })
                                            .catch(() => {
                                                // on cancel
                                            })
                                        break
                                    case '0':
                                        msg = '获取二维码失败'
                                        break
                                    case '1':
                                        msg = ''
                                        _this.$store.dispatch('setLoginUrl', content.qrUrl)
                                        _this.encode = content.qrUrl
                                        _this.haveCode = content.qrType == 1 ? 3 : 2
                                        break
                                    case '2':
                                        _this.robot.wxNick = content.wxNick
                                        Dialog.alert({
                                            title: '提示',
                                            message: '登录成功'
                                        })
                                            .then(() => {
                                                // on close
                                            })
                                        clearInterval(_this.timeControls)
                                        _this.$router.replace('/index')
                                        break
                                    case '3':
                                        msg = '登录异常：' + content.logoutReason
                                        break
                                    case '5':
                                        msg = ''
                                        _this.encode = ''
                                        _this.second = true
                                        break
                                    case '6':
                                        msg = '登录失败'
                                        break
                                    case '1081':
                                        this.showVerifyCode = true
                                        clearInterval(_this.timeControls)
                                        break
                                    default:
                                }
                                if (msg) {
                                    clearInterval(_this.timeControls)
                                    Dialog.alert({
                                        title: '提示',
                                        message: msg
                                    })
                                        .then(() => {
                                            // on close
                                        })
                                }
                            }
                        }
                        resolve()
                    })
                    .catch((e) => {
                        // _this.timeControl()
                        reject()
                    })
            })

        },
        timeControl() {
            this.showVerifyCode = false
            const _this = this
            _this.getEncode()
            clearInterval(_this.timeControls)
            _this.timeControls = setInterval(async () => {
                await _this.getEncode()
            }, 2000)
        },
        refreshs() {
            const _this = this
            _this.encode = ''
            _this.$Toast('刷新成功！')
            _this.initData()
            // _this.initData();
        }
    }
}
</script>

<style scoped lang="scss">
@import "login";
</style>
